import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { SERVER_URL } from '../Api/api';
import { Fornecedor } from '../../models/fornecedor';
import { Observable } from 'rxjs';
import { ParamsCriteria } from '../../models/paramsCriteria';

@Injectable({
  providedIn: 'root'
})
export class FornecedorService {

  constructor(private http: HttpClient) { }

  getAll(): Observable<Fornecedor[]> {
    return this.http.get<Fornecedor[]>(`${SERVER_URL}/fornecedor`);
  }

  query(req?: ParamsCriteria) {
    return this.http.get<Fornecedor[]>(`${SERVER_URL}/fornecedor`, { params: this.getParams(req), observe: 'response' });
  }

  findById(id): Observable<Fornecedor[]> {
    return this.http.get<Fornecedor[]>(`${SERVER_URL}/fornecedor/${id}`);
  }

  save(fornecedor: Fornecedor): Observable<Fornecedor> {
    return this.http.post<Fornecedor>(`${SERVER_URL}/fornecedor`, fornecedor);
  }

  update(fornecedor: Fornecedor): Observable<Fornecedor> {
    return this.http.put<Fornecedor>(`${SERVER_URL}/fornecedor/${fornecedor.id}`, fornecedor);
  }

  delete(id) {
    return this.http.delete(`${SERVER_URL}/fornecedor/${id}`);
  }

  getParams(req: ParamsCriteria) {
    let params = new HttpParams();
    params = params.append('_page',  req._page + '');
    params = params.append('_sort',  req._sort);
    params = params.append('_order', req._order);
    params = params.append('_limit', req._limit + '');
    if (req._campo) { params = params.append(req._campo, req._valor_campo); }
    return params;
  }

}

