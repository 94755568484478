import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { SERVER_URL } from '../Api/api';
import { ModeloTanque } from '../../models/modeloTanque';
import { Observable } from 'rxjs';
import { ParamsCriteria } from '../../models/paramsCriteria';

@Injectable({
  providedIn: 'root'
})
export class ModeloTanqueService {

  constructor(private http: HttpClient) { }

  getAll(): Observable<ModeloTanque[]> {
    return this.http.get<ModeloTanque[]>(`${SERVER_URL}/modeloTanque`);
  }

  query(req?: ParamsCriteria) {
    return this.http.get<ModeloTanque[]>(`${SERVER_URL}/modeloTanque`, { params: this.getParams(req), observe: 'response' });
  }

  findById(id): Observable<ModeloTanque[]> {
    return this.http.get<ModeloTanque[]>(`${SERVER_URL}/modeloTanque/${id}`);
  }

  save(modeloTanque: ModeloTanque): Observable<ModeloTanque> {
    return this.http.post<ModeloTanque>(`${SERVER_URL}/modeloTanque`, modeloTanque);
  }

  update(modeloTanque: ModeloTanque): Observable<ModeloTanque> {
    return this.http.put<ModeloTanque>(`${SERVER_URL}/modeloTanque/${modeloTanque.id}`, modeloTanque);
  }

  delete(id) {
    return this.http.delete(`${SERVER_URL}/modeloTanque/${id}`);
  }


  getParams(req: ParamsCriteria) {
    let params = new HttpParams();
    params = params.append('_page',  req._page + '');
    params = params.append('_sort',  req._sort);
    params = params.append('_order', req._order);
    params = params.append('_limit', req._limit + '');
    if (req._campo) { params = params.append(req._campo, req._valor_campo); }
    return params;
  }

}

