import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { SERVER_URL } from '../Api/api';
import { Observable } from 'rxjs';
import { ParamsCriteria } from '../../models/paramsCriteria';
import { Abastecimento } from '../../models/abastecimento';

@Injectable({
  providedIn: 'root'
})
export class AbastecimentoService {

  constructor(private http: HttpClient) { }

  query(req) {
    return this.http.get<Abastecimento[]>(`${SERVER_URL}/reviews/abastecimento`, { params: this.setParams(req) });
  }

  getParams(req: ParamsCriteria) {
    let params = new HttpParams();
    params = params.append('_page',  req._page + '');
    params = params.append('_sort',  req._sort);
    params = params.append('_order', req._order);
    params = params.append('_limit', req._limit + '');
    if (req._campo) { params = params.append(req._campo, req._valor_campo); }
    return params;
  }

  setParams(obj: any[]) {
    let params = new HttpParams();
    if (obj.length > 0) {
      for (let i = 0; i < obj.length; i++) {
        params = params.append(obj[i].chave, obj[i].valor);
      }
    }
    return params;
  }

}

