export class Transferencia {
    id?: number;
    empresa_id: number;
    tipoJustificativa: any;
    respJustificativa_id: number;
    respFornecimento: number;
    quantidade: number;
    refInicial: number;
    refFinal: number;
    justificativa: string;
    fornecedor_id: number;
    destino_id: number;
    criado_date?: any;
    consistente: boolean;
    centroCusto_id: number;
    atualizado_date: any;
    data_transferencia?:any;
}
