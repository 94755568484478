import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { SERVER_URL } from '../Api/api';
import { Manutencao } from '../../models/manutencao';
import { Observable } from 'rxjs';
import { ParamsCriteria } from '../../models/paramsCriteria';

@Injectable({
  providedIn: 'root'
})
export class ManutencaoService {

  constructor(private http: HttpClient) { }

  getAll(): Observable<Manutencao[]> {
    return this.http.get<Manutencao[]>(`${SERVER_URL}/manutencao`);
  }

  query(req?: ParamsCriteria) {
    return this.http.get<Manutencao[]>(`${SERVER_URL}/manutencao`, { params: this.getParams(req), observe: 'response' });
  }

  findById(id): Observable<Manutencao[]> {
    return this.http.get<Manutencao[]>(`${SERVER_URL}/manutencao/${id}`);
  }

  findByEquipamentoId(id): Observable<Manutencao[]> {
    return this.http.get<Manutencao[]>(`${SERVER_URL}/manutencao/equipamento/${id}`);
  }

  save(manutencao: Manutencao): Observable<Manutencao> {
    return this.http.post<Manutencao>(`${SERVER_URL}/manutencao`, manutencao);
  }

  update(manutencao: Manutencao): Observable<Manutencao> {
    return this.http.put<Manutencao>(`${SERVER_URL}/manutencao/${manutencao.id}`, manutencao);
  }

  update_last_ids(equipamento_id, list_ids){
    return this.http.put(`${SERVER_URL}/manutencao/last_ids/${equipamento_id}`, {"lista_atualizados": list_ids});
  }

  delete(id) {
    return this.http.delete(`${SERVER_URL}/manutencao/${id}`);
  }

  getParams(req: ParamsCriteria) {
    let params = new HttpParams();
    params = params.append('_page',  req._page + '');
    params = params.append('_sort',  req._sort);
    params = params.append('_order', req._order);
    params = params.append('_limit', req._limit + '');
    if (req._campo) { params = params.append(req._campo, req._valor_campo); }
    return params;
  }

}

