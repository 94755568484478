import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { SERVER_URL } from '../Api/api';
import { OS } from '../../models/os';
import { Observable } from 'rxjs';
import { ParamsCriteria } from '../../models/paramsCriteria';

@Injectable({
  providedIn: 'root'
})
export class OSService {

  constructor(private http: HttpClient) { }

  getAll(): Observable<OS[]> {
    return this.http.get<OS[]>(`${SERVER_URL}/os`);
  }

  query(req?: ParamsCriteria) {
    return this.http.get<OS[]>(`${SERVER_URL}/os`, { params: this.getParams(req), observe: 'response' });
  }

  findById(id): Observable<OS[]> {
    return this.http.get<OS[]>(`${SERVER_URL}/os/${id}`);
  }

  getOpened(id): Observable<OS[]> {
    return this.http.get<OS[]>(`${SERVER_URL}/os/opened/${id}`);
  }

  save(os: OS): Observable<OS> {
    return this.http.post<OS>(`${SERVER_URL}/os`, os);
  }

  update(os: OS): Observable<OS> {
    return this.http.put<OS>(`${SERVER_URL}/os/${os.id}`, os);
  }

  concluir(os: OS): Observable<OS> {
    return this.http.put<OS>(`${SERVER_URL}/os/concluir/${os.id}`, os);
  }

  delete(id) {
    return this.http.delete(`${SERVER_URL}/os/${id}`);
  }

  getParams(req: ParamsCriteria) {
    let params = new HttpParams();
    params = params.append('_page',  req._page + '');
    params = params.append('_sort',  req._sort);
    params = params.append('_order', req._order);
    params = params.append('_limit', req._limit + '');
    if (req._campo) { params = params.append(req._campo, req._valor_campo); }
    return params;
  }

}

